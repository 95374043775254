import React, { useState } from "react";
import "../../styles/checkout.css";
// import { Link } from "react-router-dom";

const Checkout = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let newErrors = {};

    if (!formValues.firstName) newErrors.firstName = "First name is required.";
    // if (!formValues.lastName) newErrors.lastName = "Last name is required.";
    if (!formValues.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!formValues.number) {
      newErrors.number = "Phone number is required.";
    } else if (formValues.number.length !== 10) {
      newErrors.number = "Phone number must be 10 digits.";
    }
    if (!formValues.address) newErrors.address = "Address is required.";
    if (!formValues.country) newErrors.country = "Country is required.";
    if (!formValues.city) newErrors.city = "City is required.";
    if (!formValues.state) newErrors.state = "State is required.";
    // if (!formValues.postalcode) newErrors.postalcode = "PostalCode is required.";
    if (!formValues.postalCode) newErrors.postalCode = "PostalCode is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Form submitted successfully!");
      // Add your form submission logic here
    }
  };

  return (
    <>
      <section style={{ marginBottom: "1.5rem" }}>
        <div className="container">
          <div className="checkout">
            <h1>Billing Details</h1>

            <form onSubmit={handleSubmit} className="checkout-form">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      className="form-control"
                      value={formValues.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <div className="error">{errors.firstName}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="form-control"
                      value={formValues.lastName}
                      onChange={handleChange}
                    />
                    {/* {errors.lastName && <div className="error">{errors.lastName}</div>} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="tel"
                      name="number"
                      placeholder="Number"
                      className="form-control"
                      value={formValues.number}
                      onChange={handleChange}
                    />
                    {errors.number && (
                      <div className="error">{errors.number}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      className="form-control"
                      value={formValues.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <div className="error">{errors.address}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      placeholder="City *"
                      className="form-control"
                      value={formValues.city}
                      onChange={handleChange}
                    />
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="tel"
                      name="postalCode"
                      placeholder="Postal code"
                      className="form-control"
                      value={formValues.postalCode}
                      onChange={handleChange}
                    />
                    {errors.postalCode && (
                      <div className="error">{errors.postalCode}</div>
                    )}
                  </div>
                </div>

              </div>

              <div className="row">
              <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      className="form-control"
                      value={formValues.state}
                      onChange={handleChange}
                    />
                    {errors.state && (
                      <div className="error">{errors.state}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="country"
                      placeholder="Country *"
                      className="form-control"
                      value={formValues.country}
                      onChange={handleChange}
                    />
                    {errors.country && (
                      <div className="error">{errors.country}</div>
                    )}
                  </div>
                </div>
              </div>

              <button type="submit" className="primary_btn">
                Book Now
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
