import React from "react";
import "../../styles/kalsharpdosh.css";

import navgrahimg from "../Assets/navgrahh-image.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Navgrah = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={navgrahimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Navgrah Pooja</h1>
              <div className="product_name">
                <p>Grah Shanti Pooja Se Sukh, Samriddhi Ka Anand Uthaein</p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
               <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  An Indian Vedic Astrologer, provides expert guidance for a
                  stable and clear life. With simple remedies and personal
                  attention, he empowers clients, ensuring satisfaction and
                  trust in his solutions.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Remove negative effects of all planets.</li>
                    <li>Gain victory over enemies.</li>
                    <li>Attract good health and wealth.</li>
                    <li>Remove obstacles in relationships.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>

                    <li>Input your Name and Surname/Gotra for Sankalp.</li>

                    <li>You can attend Pooja live on video call.</li>

                    <li>Take the blessings.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Navgrah</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      {" "}
                      Navagrahas are nine celestial bodies in Hindu astrology
                      that affect human life.
                    </li>
                    <li>
                      They consist of the Sun, Moon, Mars, Mercury, Jupiter,
                      Venus, Saturn, Rahu, and Ketu.
                    </li>
                    <li>
                      Each Navagraha has its own qualities, influences, and
                      cosmic energies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Give grains, fruits, and symbolic items to those in need.
                    </li>
                    <li>Do the Pooja every month for the best benefits.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>

                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navgrah;
