import React, { useEffect, useState } from "react";
import "../styles/home.css";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import bannerimg from "../Components/Assets/bannerimg1.png"
import homeimg from "../Components/Assets/bg-imagee.jpeg";
import homeimg1 from "../Components/Assets/new-bg4.jpg";
import homeimg2 from "../Components/Assets/bg-banner2.jpeg";
import homeimg3 from "../Components/Assets/bg-banner3.jpeg";
import homeimg4 from "../Components/Assets/bg-banner4.jpeg";
import aboutimg from "../Components/Assets/aboutimg.png";
import bappaimg from "../Components/Assets/bappa-img1.png";
import { Link } from "react-router-dom";

// import aboutimg1 from "../Components/Assets/bg-imagee.jpeg";
// import aboutimg2 from "../assets/mon.jpg";
// import aboutimg3 from "../assets/thurs.jpg";
// import aboutimg4 from "../assets/tues.jpg";
// import aboutimg5 from "../assets/fri.jpg";
// import aboutimg6 from "../assets/satur.jpg";
// import aboutimg7 from "../assets/sun.jpg";

import bell from "../Components/Assets/bell.png";
import { FaWhatsapp } from "react-icons/fa";
import api from "./Axios/api";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/user/services/get");

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  return (
    <>
      {/* <Slider {...settings}> */}
      {/* <div className="">
          <img src={homeimg} alt="" className="bg-image" />
        </div> */}
      <div className="">
        <img src={homeimg1} alt="" className="bg-image" />
        {/* <div className="mantra">
            <i class="fas-fa-bell mandir-bell-a">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"/>
            </i>
            <p className="first_para">
              वक्रतुण्ड महाकाय सूर्यकोटि समप्रभ। निर्विघ्नं कुरु मे देव
              सर्वकार्येषु सर्वदा ॥
            </p>
            <i class="fas-fa-bell mandir-bell-b">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"/>
            </i>
            <p className="second_para">
              ऊँ एकदंताय विद्धमहे, वक्रतुण्डाय धीमहि, तन्नो दंती प्रचोदयात् ॥
            </p>
          </div> */}
      </div>

      {/* <div className="">
          <img src={homeimg2} alt="" className="bg-image" />
          <div className="mantra">
            <i class="fas-fa-bell mandir-bell-a">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"></img>
            </i>
            <p className="first_para">
              "कर्मण्येवाधिकारस्ते मा फलेषु कदाचन। मा कर्मफलहेतुर्भूर्मा ते
              सङ्गोऽस्त्वकर्मणि।"
            </p>
            <i class="fas-fa-bell mandir-bell-b">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"></img>
            </i>
            <p className="second_para">
              अहं आत्मा गुडाकेश सर्वभूताशयस्थितः | अहं आदिः च मध्यं च भूतानामन्त
              एव च ||
            </p>
          </div>
        </div>

        <div className="">
          <img src={homeimg3} alt="" className="bg-image" />
          <div className="mantra">
            <i class="fas-fa-bell mandir-bell-a">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"></img>
              <img src={bell} alt="Description of the image" className="bell"/>
            </i>
            <p className="first_para">
              ॐ त्र्यम्बकं यजामहे सुगन्धिं पुष्टिवर्धनम् उर्वारुकमिव बन्धनान्
              मृत्योर्मुक्तिः मामृतात्॥
            </p>
            <i class="fas-fa-bell mandir-bell-b">
              {" "}
              <img src={bell} alt="Description of the image" className="bell"></img>
              <img src={bell} alt="Description of the image" className="bell"/>
            </i>
            <p className="second_para">
              नमः शिवाय च शान्ताय च शुभाय च शिवाय च शर्वाय च महादेवाय च शरण्याय
              च महेश्वराय च ||
            </p>
          </div>
        </div> */}
      {/* </Slider> */}

      {/* <section class="hero">
        <div class="hero-image">
          <img src={bannerimg} alt="Hero Image" />
        </div>
        <div class="hero-text">
          <h1>Welcome to Our Site</h1>
          <p>
            Discover amazing content and services that cater to all your needs.
          </p>
          <a href="#services" class="btn-primary">
            Get Started
          </a>
        </div>
      </section> */}

      {/* home section end */}

      {/* about section start */}

      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img src={aboutimg} alt="" className="about-img" />
            </div>

            <div className="col-sm-6">
              <div className="title">
                <h2>About Us</h2>
                <h5>
                  How <span>Prabhu Pooja</span> Works
                </h5>
                <p>
                  Online Pooja and Astrology Services In today's digital age,
                  the ancient practices of Hindu rituals and astrology have
                  seamlessly integrated with technology to offer online Pooja
                  and astrology services. These services cater to devotees
                  worldwide, allowing them to participate in sacred rituals and
                  seek astrological guidance from the comfort of their homes.
                </p>

                <button className="btn about-btn">
                  <Link to="/about">Read More</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="aboutSection">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <div className="slider-container">
                <Slider {...settings1}>
                  <div className="aboutbox">
                    <img src={aboutimg1} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg2} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg3} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg4} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg5} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg6} alt="" className="aboutimage" />
                  </div>
                  <div className="aboutbox">
                    <img src={aboutimg7} alt="" className="aboutimage" />
                  </div>
                </Slider>
              </div>
            </div>

            <div className="col-sm-5">
              <div className="aboutText">
                <h4 className="aboutTitle animate__animated animate__fadeInRight">
                  Sri Hanuman Archana And Abhishekam
                </h4>
                <p className="aboutDes animate__animated animate__fadeInRight">
                  One of the main reason Tuesday is dedicated to Lord Hanuman is
                  because he was born on the full moon day (Purnima) of the
                  month on Tuesday. Hence Tuesday is considered very auspicious
                  day to worship Lord Hanuman.
                </p>
                <button className="Booknow animate__animated animate__fadeInRight">
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* about section end */}

      {/* service section start */}

      {/* <div className="services">
        <div className="services-title">
          <h1>our services</h1>
        </div>

        {loading ? (
          <p>Loading services...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="table-box">
            {services.map((service) => (
              <div className="box" key={service.id}>
                <Link to={`/${slugify(service.name)}`}>
                  <img src={`${service.image}`} alt={service.name} />
                  <p>{service.name}</p>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div> */}

      <div className="services">
        <div className="services-title">
          <h1>our services</h1>
          <div className="bappaimgsection">
            <img src={bappaimg} alt="" className="bappaimg" />
          </div>
        </div>

        {loading ? (
          <p>Loading services...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="table-box">
            {services.map((service) => (
              <div
                className="box"
                key={service.id}
                onClick={(e) => e.currentTarget.classList.toggle("move-up")}
              >
                <Link to={`/${slugify(service.name)}`}>
                  <img src={`${service.image}`} alt={service.name} />
                  <p>{service.name}</p>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* service section end */}

      {/* WhatsApp icon */}
      <a
        href="https://wa.me/7225016699?text=Namaste"
        className="whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
        text="welcome to prrabhu pooja"
      >
        <FaWhatsapp size={40} color="white" />
      </a>
    </>
  );
};

export default Home;
