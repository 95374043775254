import React, { useState } from "react";
import "../../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import api from "../Axios/api";

const Login = () => {

  const [mobile, setMobile] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [numberError, setNumberError] = useState("");

  const validateNumber = () => {
    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      setNumberError("Please enter a valid 10-digit mobile number");
    } else {
      setNumberError("");
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateNumber();
    try {
      const response = await api.post('/users/login', {
        mobile,

      });
      console.log("here is the user",response.data)
      if(response.data.token){
        localStorage.setItem('token',JSON.stringify(response.data.token));
        localStorage.setItem('users', JSON.stringify(response.data.user))
        navigate('/otp');
      }
      console.log('login success:', response.data);


      

    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Login failed. Please try again later.');
      }
    }
  };


  return (
    <div className="addUser">
      <h3>Sign in</h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="email">Number:</label>
          <input

            type="text"
            id="number"
            autoComplete="off"
            placeholder="Enter your Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            onBlur={validateNumber}
            
          />
          {numberError && <p className="error">{numberError}</p>}
          <button type="submit" class="btn btn-primary">
            {/* <Link to="/otp" style={{textDecoration: "none", color: "white"}}> Continue </Link> */}
           
              Verify OTP
           
          </button>

        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
      <div className="login">
        <p>Don't have Account? </p>
        <Link to="/signup" type="submit" class="btn btn-success">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default Login;
