import React, { useState } from "react";

import { Link } from "react-router-dom";

import "../../styles/ecommerce.css";
import kanhaimage from "../Assets/kanha-img.jpeg";
import ganeshimg from "../Assets/ganesh-img.jpeg";
import rudrakshimg from "../Assets/rudrakshimg1.webp";
import evileyeimg1 from "../Assets/evileye-img1.jpeg";

const Ecomerce = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (e) => {
    e.currentTarget.classList.toggle("move-up");
  };

  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>E-commerce</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">E-commerce</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <section className="section" style={{ paddingTop: ".5rem" }}>
        <div className="container">
          <h1 className="section-title" style={{ marginBottom: "3rem" }}>
            Products
          </h1>
          <div className="row">
            <div className="col-sm-12">
              <div className="card filterbox shadow-sm">
                <div className="card-header bg-white d-flex justify-content-between">
                  <Link>
                    <i class="fa-solid fa-filter"></i> Filter
                  </Link>
                  <Link>
                    Price <i class="fa-solid fa-arrow-down-short-wide"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-4">
                  <div
                    className="tp-box1"
                    style={{ marginTop: "30px" }}
                    onClick={handleClick}
                  >
                    <div className="tp-img">
                      <Link to="/productdetails">
                        <img src={kanhaimage} alt="" />
                      </Link>
                    </div>
                    <div className="tp-box-content">
                      <Link to="/productdetails">
                        <h6>Prabhu Pooja | laddu gopal murti</h6>
                      </Link>
                      <div className="price-booknow">
                        <div className="price-group">
                          <p className="original-price">Rs.499</p>
                          <p className="current-price">
                            <strong>
                              <span>Rs.299</span>
                            </strong>
                          </p>
                        </div>
                        <Link className="booknow-btn" to="/buynowform">Buy Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="tp-box1" style={{ marginTop: "30px" }}>
                    <div className="tp-img">
                      <Link to="/productdetails">
                        <img src={ganeshimg} alt="" />
                      </Link>
                    </div>
                    <div className="tp-box-content">
                      <Link to="/productdetails">
                        <h6>Prabhu Pooja | Ganesha murti</h6>
                      </Link>
                      <div className="price-booknow">
                        <div className="price-group">
                          <p className="original-price">Rs.450</p>
                          <p className="current-price">
                            <strong>
                              <span>Rs.349</span>
                            </strong>
                          </p>
                        </div>
                        <Link className="booknow-btn" to="/buynowform">Buy Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="tp-box1" style={{ marginTop: "30px" }}>
                    <div className="tp-img">
                      <Link to="/productdetails">
                        <img src={rudrakshimg} alt="" />
                      </Link>
                    </div>
                    <div className="tp-box-content">
                      <Link to="/productdetails">
                        <h6>Prabhu Pooja | Rudraksh</h6>
                      </Link>
                      <div className="price-booknow">
                        <div className="price-group">
                          <p className="original-price">Rs.350</p>
                          <p className="current-price">
                            <strong>
                              <span>Rs.199</span>
                            </strong>
                          </p>
                        </div>
                        <Link className="booknow-btn" to="/buynowform">Buy Now</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="tp-box1" style={{ marginTop: "30px" }}>
                    <div className="tp-img">
                      <Link to="/productdetails">
                        <img src={evileyeimg1} alt="" />
                      </Link>
                    </div>
                    <div className="tp-box-content">
                      <Link to="/productdetails">
                        <h6>Prabhu Pooja | EvilEye</h6>
                      </Link>
                      <div className="price-booknow">
                        <div className="price-group">
                          <p className="original-price">Rs.250</p>
                          <p className="current-price">
                            <strong>
                              <span>Rs.149</span>
                            </strong>
                          </p>
                        </div>
                        <Link className="booknow-btn" to="/buynowform">Buy Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ecomerce;
