import React from "react";
import "../../styles/productdetail.css";

import rudrakshimage from "../Assets/rudrakshimg1.webp";
import { Link } from "react-router-dom";

const Productdetails = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="product_title">Prabhu Pooja | Rudraksh</h1>

          <div className="row">
            <div className="col-sm-8">
              <div className="product-img mb-5">
                <Link>
                  <img src={rudrakshimage} alt="" />
                </Link>
              </div>
              <div className="product">
                <div className="product-tabs">
                  <button className="product-link active">Details</button>
                </div>
              </div>
              <div className="product-content">
                <div className="product-pane active">
                  <div>
                    <span>Theme:</span>
                    <span> Rudraksh</span>
                  </div>
                  <div>
                    <span>Brand:</span>
                    <span> Prabhu Pooja</span>
                  </div>
                  <div>
                    <span>Colour:</span>
                    <span> Golden</span>
                  </div>
                  <div>
                    <span>Style:</span>
                    <span> Traditional</span>
                  </div>
                  <div>
                    <span>Material:</span>
                    <span> Bronze, Copper</span>
                  </div>
                  <div>
                    <span>Special Feature:</span>
                    <span> handmade</span>
                  </div>
                  <div>
                    <span>Number of Pieces:</span>
                    <span> 1</span>
                  </div>
                  <div>
                    <span>Number of Items:</span>
                    <span> 1</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <form action="" className="package-form">
                <div
                  className="product-box"
                  style={{
                    background: "rgb(248, 117, 69)",
                    color: "#fff",
                    padding: "8px 8px",
                    marginBottom: "5px",
                  }}
                >
                  <h6 className="product_current_price">
                    <strong style={{ textDecoration: "line-through" }}>
                      RS.450
                    </strong>
                    <strong> RS.299</strong>
                  </h6>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    Quantity: <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input"
                    required
                    min="1"
                    max="10"
                    defaultValue="1"
                  />
                </div>

                <Link to="/checkout">
                  <button className="btn">Buy Now</button>{" "}
                </Link>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Productdetails;
