import React from "react";
import "../../styles/buynowform.css";
import { Link } from "react-router-dom";

function BuyNowForm() {
  return (
    <div className="buynowform">
      <h1 className="buynow-title">Buy Now</h1>
      <div className="buynow-form-container">
        <form className="buynow-form">
          <div className="buynow-price-box">
            <h6 className="current-price-product">
              <span className="old-price">RS.450</span>
              <span className="current-price">RS.299</span>
            </h6>
          </div>

          <div className="form-group-box">
            <label htmlFor="quantity">
              Quantity: <span>*</span>
            </label>
            <input
              type="number"
              id="quantity"
              className="input-product"
              required
              min="1"
              max="10"
              defaultValue="1"
            />
          </div>

          <Link to="/checkout">
            <button className="buynow-btn">Buy Now</button>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default BuyNowForm;
