import React, { useState } from "react";

const Booknowform = () => {
  const [phone, setPhone] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      alert("Please enter a valid 10-digit phone number");
      return;
    }

    // If all validations pass
    alert("Form submitted successfully!");
    // You can add form submission logic here
  };
  return (
    <>
      <div className="addUser">
        <h3>Book Temple</h3>
        <form className="addUserForm" onSubmit={handleSubmit}>
          <div className="inputGroup">
            <label htmlFor="name">Desired Date (Atleast 3 days later)</label>
            <input
              type="date"
              id="name"
              required
              autoComplete="off"
              placeholder="Desired Date (Atleast 3 days later)"
              min={new Date().toISOString().split("T")[0]}
            />

            <label htmlFor="number">Name:</label>
            <input
              type="text"
              id="number"
              autoComplete="off"
              placeholder="Name"
              required
            />

            <label htmlFor="email">Number:</label>
            <input
              type="text"
              id="email"
              required
              autoComplete="off"
              placeholder="Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <label htmlFor="city">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              required
              autoComplete="off"
              placeholder="Date of Birth"
              max={today} // Restricts to past dates
            />

            <label htmlFor="city">Rashi /Zodiac Sign:</label>
            <select id="zodiacSign" name="zodiacSign" className="rashi_field">
              <option value="">Select Rashi</option>
              <option value="Mesha">Mesha (Aries)</option>
              <option value="Vrishabha">Vrishabha (Taurus)</option>
              <option value="Mithuna">Mithuna (Gemini)</option>
              <option value="Karka">Karka (Cancer)</option>
              <option value="Simha">Simha (Leo)</option>
              <option value="Kanya">Kanya (Virgo)</option>
              <option value="Tula">Tula (Libra)</option>
              <option value="Vrishchika">Vrishchika (Scorpio)</option>
              <option value="Dhanu">Dhanu (Sagittarius)</option>
              <option value="Makara">Makara (Capricorn)</option>
              <option value="Kumbha">Kumbha (Aquarius)</option>
              <option value="Meena">Meena (Pisces)</option>
            </select>

            <label htmlFor="city">Gotram:</label>
            <input
              type="text"
              id="city"
              autoComplete="off"
              placeholder="Gotram"
            />

            <button type="submit" className="btn btn-success">
              Book Now
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Booknowform;
