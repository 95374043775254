import React from "react";
import "../../styles/temple.css";

import { Link } from "react-router-dom";
import khajranatempleimg from "../Assets/khajrana-temple1.jpeg";
import ujjaintempleimg from "../Assets/ujjain-temple1.jpeg";
import mapimg from "../Assets/map-temple-image.png";

import { IoCheckmark } from "react-icons/io5";

const Temple = () => {
  return (
    <>
      {/* <div className="breadcrumb-header">
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="breadcrumb-text">
              <h1>Temple</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link className="breadcrumb-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">Temple</li>
              </ol>
            </nav>
          </div>
        </div>
      </div> */}

      <div className="temple-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <div className="temple-intro">
                <h1>Visit the holy places and temples of India.</h1>
              </div>

              <div className="temple-features">
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Learn about their culture and history.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Find temples of your favorite gods.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Do charity work and donate to these temples.
                </p>
              </div>
            </div>

            <div className="col-sm-5">
              <Link>
                <img src={mapimg} alt="Map of Temples" className="temple-map-image" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="temple-gallery">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <ul className="temple-gallery-list">
                <div className="scroll-container">
                  <div className="scroll-content">
                    <li className="temple-gallery-item">
                      <div>
                        <Link to="/khajranatemple">
                          <img
                            src={khajranatempleimg}
                            alt="Khajrana Ganesh Mandir"
                            height={245}
                            className="temple-image"
                          />
                        </Link>
                        <div className="temple-info">
                          <div className="temple-title">
                            Khajrana Ganesh Mandir
                          </div>
                          <div className="temple-description">
                            Indore, Madhya Pradesh
                          </div>
                          <div className="temple-price">₹ 1151</div>
                          <div className="temple-actions">
                            <Link className="temple-action-link" to="/khajranatemple">
                              <button>VIEW</button>
                            </Link>
                            <Link
                              className="temple-action-link book-action-link"
                              to="/booknowform"
                            >
                              <button>BOOK</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
              </ul>
            </div>

            <div className="col-sm-4">
              <ul className="temple-gallery-list">
                <div className="scroll-container">
                  <div className="scroll-content">
                    <li className="temple-gallery-item">
                      <div>
                        <Link to="/ujjaintemple">
                          <img
                            src={ujjaintempleimg}
                            alt="Mahakaleshwar Mandir"
                            height={245}
                            className="temple-image"
                          />
                        </Link>
                        <div className="temple-info">
                          <div className="temple-title">
                            Mahakaleshwar Mandir
                          </div>
                          <div className="temple-description">
                            Ujjain, Madhya Pradesh
                          </div>
                          <div className="temple-price">₹ 1151</div>
                          <div className="temple-actions">
                            <Link className="temple-action-link" to="/ujjaintemple">
                              <button>VIEW</button>
                            </Link>
                            <Link
                              className="temple-action-link book-action-link"
                              to="/booknowform"
                            >
                              <button>BOOK</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Temple;