import React, { useEffect, useState } from "react";

import axios from "axios";

import "../../styles/onlinepuja.css";

import { Link } from "react-router-dom";
import api from "../Axios/api";

// import kalsharpimg from "../Assets/kaalsharp-image.jpeg";
// import rahuketuimg from "../Assets/rahuketuu-image.jpeg";
// import pitradoshimg from "../Assets/pitraa-dosh-image.jpeg";
// import navgrahimg from "../Assets/navgrahh-image.jpeg";
// import mangaldoshimg from "../Assets/mangal-dosh-image.jpeg";
// import satyanarayanimg from "../Assets/satyaanarayan-image.jpeg";
// import mahamritunjayjaapimg from "../Assets/maha-mritunejay-image.jpeg";
// import sundarkandimg from "../Assets/sundarkandd-image.jpeg";
// // import grahandoshimg from "../Assets/grahan-dosh.jpeg";
// import vastupoojaimg from "../Assets/vastu-shanti-img.jpeg";
// import rudraabhishekimg from "../Assets/rudra-abhishek-image.jpeg";
// import rinmuktipoojaimg from "../Assets/rin-muktii-image.jpeg";
// import siddhivinayakimg from "../Assets/sidhi-vinayak-image.jpeg";

const Onlinepuja = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get(
          "user/onlinePuja/get"
        );

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>Online Pooja</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">Online Pooja</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="puja_category">
        <div className="container">
          <div className="puja_title">POOJA CATEGORIES</div>

          <div className="row" style={{ marginTop: "50px" }}>
            {services.map((service) => (
            <div className="col-sm-3">
            
              <div className="tp-box"  key={service.id}>
                
                <div className="tp-img">
                  <Link  to={`/${slugify(service.name)}/${service.id}`}>
                  <img
                  src={service.image}
                  alt={service.name}
                />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>{service.name}</h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.{service.price}</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to={`/${slugify(service.name)}`}>
                      Book Now
                    </Link>
                  </div>
                </div>
                
              </div>
              
            </div>
            ))}
{/* 
            <div className="col-sm-3">
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/rahuketupooja">
                    <img src={rahuketuimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Rahu Ketu Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/rahuketupooja">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/pitradoshpooja">
                    <img src={pitradoshimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Pitra Dosh Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/pitradoshpooja">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/navgrahpooja">
                    <img src={navgrahimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Navgrah Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/navgrah">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/mangaldoshnivaranpooja">
                    <img src={mangaldoshimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Mangal Dosh Nivaran Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/mangaldosh">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/kaalsarpdosh">
                    <img src={kalsharpimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Kaal Sarp Dosh Nivaran Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5500</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/satyanarayan">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/satyanarayanpooja">
                    <img src={satyanarayanimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Satyanarayan Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5500</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/satyanarayan">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/shrimahamritunjayjaap">
                    <img src={mahamritunjayjaapimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Shri Maha Mritunjay jaap </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.8999</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/shrimahamritunejayjaap">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/sundarkandpathpooja">
                    <img src={sundarkandimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Sundarkand Path Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.11000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/sundarkand">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/vastushantipooja">
                    <img src={vastupoojaimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Vastu Shanti Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.11000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/vastushanti">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/rudraabhishek">
                    <img src={rudraabhishekimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Rudraabhishek </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.11000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/rudraabhishek">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/rinmuktipooja">
                    <img src={rinmuktipoojaimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Rin Mukti Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.11000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/rinmukti">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3" style={{ marginTop: "50px" }}>
              <div className="tp-box">
                <div className="tp-img">
                  <Link to="/siddhivinayakpooja">
                    <img src={siddhivinayakimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Siddhi Vinayak Pooja </h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.11000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/siddhivinayak">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Onlinepuja;
