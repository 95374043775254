import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Video from "twilio-video";

import { FaPhoneSlash } from "react-icons/fa6";
import { FaMicrophone } from "react-icons/fa6";
import { FaMicrophoneSlash } from "react-icons/fa6";
import { BsCameraVideoFill } from "react-icons/bs";
import { BsCameraVideoOffFill } from "react-icons/bs";

const VideoCall = () => {
  const { roomName, token } = useParams();
  const [room, setRoom] = useState(null);
  const [streaming, setStreaming] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && roomName) {
      connectToRoom();
    } else {
      console.error("Missing token or roomName");
    }

    return () => {
      if (room) {
        room.disconnect();
        cleanupVideoElements();
      }
    };
  }, [token, roomName]);

  const connectToRoom = async () => {
    try {
      const room = await Video.connect(token, {
        name: roomName,
        audio: true,
        video: { width: 640 },
      });

      setRoom(room);

      const localParticipant = room.localParticipant;
      cleanupVideoElements();
      attachParticipantTracks(localParticipant, localVideoRef.current);

      room.on("participantConnected", (participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            attachTrack(publication.track, remoteVideoRef.current);
          }
        });

        participant.on("trackSubscribed", (track) => {
          attachTrack(track, remoteVideoRef.current);
        });

        participant.on("trackUnsubscribed", (track) => {
          detachTrack(track, remoteVideoRef.current);
        });
      });

      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            attachTrack(publication.track, remoteVideoRef.current);
          }
        });

        participant.on("trackSubscribed", (track) => {
          attachTrack(track, remoteVideoRef.current);
        });

        participant.on("trackUnsubscribed", (track) => {
          detachTrack(track, remoteVideoRef.current);
        });
      });
    } catch (error) {
      console.error("Failed to connect to room:", error);
    }
  };

  const cleanupVideoElements = () => {
    if (localVideoRef.current) {
      localVideoRef.current.innerHTML = "";
    }
    if (remoteVideoRef.current) {
      remoteVideoRef.current.innerHTML = "";
    }
  };

  const attachParticipantTracks = (participant, container) => {
    participant.tracks.forEach((publication) => {
      if (publication.track) {
        attachTrack(publication.track, container);
      }
    });
  };

  const attachTrack = (track, container) => {
    if (track.kind === "video") {
      container.appendChild(track.attach());
    }
  };

  const detachTrack = (track, container) => {
    if (track.kind === "video") {
      track.detach().forEach((element) => element.remove());
    }
  };

  const handleEndCall = () => {
    if (room) {
      room.disconnect();
      cleanupVideoElements();
    }
    setStreaming(false);
    navigate("/astrology");
  };

  const toggleMute = () => {
    const localParticipant = room.localParticipant;
    localParticipant.audioTracks.forEach((publication) => {
      if (isMuted) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
    setIsMuted(!isMuted);
  };

  const toggleVideo = () => {
    const localParticipant = room.localParticipant;
    localParticipant.videoTracks.forEach((publication) => {
      if (isVideoOff) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
    setIsVideoOff(!isVideoOff);
  };

  return (
    <div className="video_box">
      <div className="container">
        <div className="localContainer">
          <div ref={localVideoRef}></div>
        </div>
        <div className="remoteContainer">
          <div ref={remoteVideoRef}></div>
        </div>
        {streaming && (
          <div className="buttonContainer">
            <button onClick={toggleMute} className="endcall">
              {isMuted ? <FaMicrophone /> : <FaMicrophoneSlash />}
            </button>
            <button onClick={toggleVideo} className="endcall">
              {isVideoOff ? <BsCameraVideoOffFill /> : <BsCameraVideoFill />}
            </button>
            <button onClick={handleEndCall} className="endcall">
              <FaPhoneSlash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCall;
