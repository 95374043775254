import React from "react";
import "../../styles/kalsharpdosh.css";

import mangaldoshimg from "../Assets/mangal-dosh-image.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Mangaldosh = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={mangaldoshimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Mangal Dosh Nivaran Pooja</h1>
              <div className="product_name">
                <p>Manglik Dosha Se Hui Vivah Badhaon Ko Door Karein</p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login">  <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  Veer’s ability to predict upcoming situations accurately has
                  earned him an impressive rating of 4.97, with over 20,000
                  users expressing their satisfaction.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>This Pooja stops injuries and accidents.</li>
                    <li>It helps fix marriage delays.</li>
                    <li>It gets rid of health problems</li>
                    <li>It brings happiness and wealth.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>Input your Name and Surname/Gotra for Sankalp.</li>
                    <li>You can attend Pooja live on video call.</li>
                    <li>Take the blessings.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Mangal Dosh Nivaran Pooja</h2>
                <div className="next_details">
                  <ul>
                    <li>Mangal Dosha Nivaran Pooja will fix marriage delays
                    completely.</li>
                    <li>It will ensure a happy and successful married life.</li>
                    <li>It removes all obstacles to financial growth.</li>
                    <li>This Pooja brings blessings from the Mangal planet
                    for health and prosperity.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Hold rice or flowers in your hands during the puja, and
                    afterward, place them on a tree, plant, or at your place of
                    worship.</li>
                    <li>Donate masoor lentils (मसूर की दाल) or wheat (गेहूँ) to the poor.</li>
                    <li>Perform the Pooja every month to maximize the benefits.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>Prabhu Pooja is India's largest Devotion tech platform,
                    offering access to top astrologers and Pandits.</li>
                    <li>Our Pooja services feature the best Pandits on the
                    platform to ensure you receive the maximum benefits.</li>
                    <li>This Pooja includes family participation and covers
                    Dakshina as well.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mangaldosh;
