import React from "react";
import "../../styles/kalsharpdosh.css";

import siddhivinayakimg from "../Assets/sidhi-vinayak-image.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
 import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Siddhivinayak = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={siddhivinayakimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Siddhi Vinayak Pooja</h1>
              <div className="product_name">
                <p>Siddhi Vinayak Pooja se samriddhi, safalta aur mangal ka labh hota hai.</p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  An Indian Vedic Astrologer, offers expert guidance aimed at
                  fostering stability and clarity in life. Through
                  straightforward remedies and personalized attention, he
                  empowers clients, ensuring their satisfaction and trust in his
                  solutions.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About SATYANARAYAN Pooja</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Worship of Lord Ganesha: Performed to honor Lord Ganesha,
                      the remover of obstacles.
                    </li>
                    <li>
                      Blessings for Success: Aimed at seeking blessings for
                      success, prosperity, and wisdom.
                    </li>
                    <li>
                      Offerings: Devotees offer flowers, sweets, and prayers to
                      Lord Ganesha.
                    </li>
                    <li>
                      New Beginnings: Often conducted at the start of new
                      ventures or important events.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Charity: Donate to those in need.</li>
                    <li>
                      Continue reciting Ganesha mantras like "Om Gan Ganapataye
                      Namah".
                    </li>
                    <li>
                      Regular worship of Lord Ganesha into your daily routine.
                      This can be a simple prayer, offering flowers, or lighting
                      a lamp.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>
                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Siddhivinayak;
