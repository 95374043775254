import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import "../../styles/prasaddelivery.css";
import ujjainprasadimg from "../Assets/ujjain-prasad-image.jpeg";
import khajranaprasad from "../Assets/khajrana-mandir.jpeg";
import api from "../Axios/api"

const Prasaddelivery = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get(
          "/user/prasad/get"
        );

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '') 
      .replace(/\-/g, '')
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }


  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>Prasad Delivery</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">Prasad Delivery</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <h1 className="section_title">PRASAD DELIVERY</h1>

          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-sm-3">
              <div className="tp-box-prasad">
                <div className="tp-img">
                  <Link to="/khajranamandir">
                    <img src={khajranaprasad} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>
                    {services.prasad_name} - {services.temple_name} GIFT BOX</h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.500</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/checkout">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="tp-box-prasad">
                <div className="tp-img">
                  <Link to="/mahakalmandirujjain">
                    <img src={ujjainprasadimg} alt="" />
                  </Link>
                </div>

                <div className="tp-box-content">
                  <h6>Ujjain - Temple GIFT BOX</h6>
                  <div className="price-booknow">
                    <div className="price-grp">
                      <p className="current_price">
                        <strong>
                          <span>Rs.5000</span>
                        </strong>
                      </p>
                    </div>
                    <Link className="booknow_btn" to="/checkout">
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section">
        <div className="container">
          <h1 className="section_title">PRASAD DELIVERY</h1>

          <div className="row" style={{ marginTop: "50px" }}>
            {services.map((service) => (
              <div key={service.id} className="col-sm-4">
                <div className="tp-box">
                  <div className="tp-img">
                    <Link to={`/${slugify(service.temple_name)}`}>
                    <img
                  src={`http://localhost:3003/uploads/${service.image}`}
                  alt={service.prasad_name}
                />
                    </Link>
                  </div>

                  <div className="tp-box-content">
                    <h6>{service.temple_name}-{service.prasad_name}</h6>
                    <div className="price-booknow">
                      <div className="price-grp">
                        <p className="current_price">
                          <strong>
                            <span>Rs. {service.price}</span>
                          </strong>
                        </p>
                      </div>
                      <Link className="booknow_btn" to={`/${slugify(service.temple_name)}`}>
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Prasaddelivery;
