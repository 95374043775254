import React from "react";
import "../../styles/kalsharpdosh.css";

import vastudoshimg from "../Assets/vastu-shanti-img.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
 import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Vastushanti = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={vastudoshimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Vastu Shanti Pooja</h1>
              <div className="product_name">
                <p>
                  Vastu Shanti Pooja se ghar ya naye bhavan mein shubhata aur
                  samriddhi ka labh hota hai.
                </p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME”, an Indian Vedic Astrologer, offers expert guidance
                  aimed at fostering stability and clarity in life. Through
                  straightforward remedies and personalized attention, he
                  empowers clients, ensuring their satisfaction and trust in his
                  solutions.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Compliance with Vastu Principles: Aligns the home with
                      Vastu principles, ensuring long-term benefits and
                      stability.
                    </li>
                    <li>
                      Prosperity and Success: Attracts prosperity, wealth, and
                      success in all endeavors.
                    </li>
                    <li>
                      Removal of Negative Energies: Purifies the space, removing
                      negative energies and influences.
                    </li>
                    <li>
                      Harmonious Living Environment: Ensures peace, harmony, and
                      balance within the home.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                    <li>You can attend Pooja live on video call.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Vastu Shanti Pooja</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Purpose: Conducted to purify the home and ensure peace,
                      harmony, and prosperity.
                    </li>
                    <li>
                      Deities: Worship includes Vastu Purusha, Lord Ganesha, and
                      Goddess Lakshmi, among others.
                    </li>
                    <li>
                      Rituals: Involves rituals like Havan (fire ceremony),
                      chanting of mantras, and offering of flowers, fruits, and
                      sweets.
                    </li>
                    <li>
                      Timing: Typically performed during auspicious occasions
                      such as moving into a new home or significant life events.
                    </li>
                    <li>
                      Benefits: Aims to remove negative energies, attract
                      positive vibrations, and bless the household with health,
                      wealth, and well-being.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Regular Worship: Continue daily or regular worship
                      practices in your home to sustain the divine presence and
                      positivity.
                    </li>
                    <li>
                      Follow Vastu Principles: Adhere to Vastu principles in
                      your home's layout and decor to maintain harmony and
                      balance.
                    </li>
                    <li>
                      Charity: Engage in acts of charity and help the needy, as
                      giving back enhances the blessings received.
                    </li>
                    <li>
                      Light Lamps and Incense: Light lamps or incense sticks
                      regularly to keep the atmosphere pure and sacred.
                    </li>
                    <li>
                      Consult Vastu Experts: Periodically consult Vastu experts
                      to ensure that your home continues to align with Vastu
                      principles.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>
                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vastushanti;
