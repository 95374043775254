import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import api from "../Axios/api";

const ChatScreen = () => {
  const { astrologerId, price, requestId } = useParams();
  console.log(astrologerId, price, requestId);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const startTimeRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    fetchMessages();
    fetchBalance();

    const interval = setInterval(fetchMessages, 5000);
    startTimeRef.current = Date.now();

    return () => {
      clearInterval(interval);
      // Delay handleEndChat to ensure balance is updated
      setTimeout(handleEndChat, 1000);
    };
  }, []); // Fetch balance and messages on mount only

  const fetchMessages = async () => {
    try {
      const response = await api.get(
        `/chats/messages/${user.id}/${astrologerId}`
      );
      setMessages(response.data.messages);
      setLoading(false); // Stop loading once messages are fetched
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await api.get(`/users/balance/${user.id}`);
      setBalance(response.data.balance);
      console.log("Fetched balance:", response.data.balance); // Verify fetched balance
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  console.log("balance is here:", balance);

  const handleEndChat = async () => {
    const endTime = Date.now();
    const durationInMinutes = Math.ceil(
      (endTime - startTimeRef.current) / 60000
    );
    const totalCost = durationInMinutes * parseFloat(price);

    console.log("Total cost:", totalCost);

    try {
      // Fetch the latest balance to ensure it's accurate
      const response = await api.get(`/users/balance/${user.id}`);
      const numericBalance = parseFloat(response.data.balance); // Fetch balance again

      console.log("Balance (numeric):", numericBalance);

      if (totalCost <= numericBalance) {
        const deductResponse = await api.post("/users/deductBalance", {
          userId: user.id,
          astrologerId, // Ensure astrologerId is included
          minutes: durationInMinutes,
        });

        if (deductResponse.data.success) {
          setBalance(deductResponse.data.newBalance); // Update state with numeric balance
        } else {
          alert("Failed to deduct balance: " + deductResponse.data.message);
        }
      } else {
        alert("Insufficient balance! Please recharge.");
      }
    } catch (error) {
      console.error("Failed to deduct balance:", error);
      alert("Error occurred while deducting balance.");
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await api.post("/chats/messages/create", {
          senderId: user.id,
          receiverId: astrologerId,
          message: newMessage,
          request_id: requestId,
        });
        setNewMessage("");
        fetchMessages();
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    }
  };

  // Function to handle key press events
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default action (new line)
      handleSendMessage();
    }
  };

  return (
    <>
      <div className="chatbox">
        <div className="container">
          {loading ? ( // Show loader if loading is true
            <div className="loader">Loading...</div>
          ) : (
            <div className="message-container">
              {messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`message-bubble ${
                    msg.sender_id === user.id ? "sent" : "received"
                  }`}
                >
                  <p className="message-text">{msg.message}</p>
                </div>
              ))}
            </div>
          )}

          <div className="input-container">
            <input
              type="text"
              className="text-input"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message"
              onKeyDown={handleKeyPress} // Add onKeyDown handler
            />
            <button className="send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;
