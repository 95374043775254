
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../styles/panditkicss.css";
import api from "../Axios/api";

const Pandit = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/pandit/pandit");

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>Pandit</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">Pandit</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <section className="services-section">
        <div className="services-container">
          <div className="services-row">
            {services.map((service, index) => (
              <div className="service-card" key={index}>
                <div className="service-image">
                  <Link to={`/panditprofile/${slugify(service.id)}`}>
                    <img
                      src={`${service.profileImage}`}
                      alt={service.name}
                      className="service-img"
                    />
                  </Link>
                </div>

                <div className="service-details">
                  <h6 className="service-name">
                    <Link to={`/panditprofile/${slugify(service.id)}`}>
                      {service.name}
                    </Link>
                  </h6>
                  <div className="service-rating">
                    <div className="rating-stars">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                    <div className="rating-info">
                      <span className="average-rating">0</span> |
                      <span className="review-count">0 reviews</span>
                    </div>
                  </div>

                  <p className="service-language">
                    <i className="fa-solid fa-language"></i> {service.language}
                  </p>
                  <p className="service-experience">
                    <i className="fa-solid fa-suitcase"></i> Exp: {service.experience} Years
                  </p>
                  <p className="service-price">
                    <i className="fa-solid fa-rupee-sign"></i> Starting From Rs.1100
                    / 30 minutes
                  </p>
                  <div className="service-action">
                    <Link className="enquire-btn" to="/enquiryform">
                      Enquire Now
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pandit;
