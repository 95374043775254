export const navItems=[
    {
        id:1,
        title: "Home",
        path: "./",
        cName: "nav-item",
    },
    {
        id:2,
        title: "About Us",
        path: "./about",
        cName: "nav-item",
    },
    {
        id:3,
        title: "Services",
        path: "./onlinepooja",
        cName: "nav-item",
    },
    {
        id:4,
        title: "Contact Us",
        path: "./enquiryform",
        cName: "nav-item",
    },
];

export const serviceDropdown = [
    {
        id:1,
        title: "Membership",
        path: "./membership",
        cName: "submenu-item",
    },
    {
        id:2,
        title: "Online Pooja",
        path: "./onlinepooja",
        cName: "submenu-item",
    },
    {
        id:3,
        title: "Prasad Delivery",
        path: "./prasaddelivery",
        cName: "submenu-item",
    },
    {
        id:4,
        title: "Astrology",
        path: "./astrology",
        cName: "submenu-item",
    },
    {
        id:5,
        title: "Muhurat",
        path: "./muhurat",
        cName: "submenu-item",
    },
    {
        id:6,
        title: "E-Commerce",
        path: "./ecommerce",
        cName: "submenu-item",
    },
    {
        id:7,
        title: "Temple",
        path: "./temple",
        cName: "submenu-item",
    },
    {
        id:8,
        title: "Pandit",
        path: "./pandit",
        cName: "submenu-item",
    },
    {
        id:9,
        title: "Yoga",
        path: "./yoga",
        cName: "submenu-item",
    },
]