import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../Axios/api";

const Poojapage = () => {
  const [pujaDetails, setPujaDetails] = useState(null);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isPastDate, setIsPastDate] = useState(false);
  const [pujaTime, setPujaTime] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    if (id) {
      fetchPujaDetails();
      
    } else {
      console.error("ID is undefined");
    }
  }, [id]);

  useEffect(() => {
    if (id && pujaDetails) {
      checkPaymentStatus();
    }
  }, [id, pujaDetails]);

  const checkPaymentStatus = async () => {
    try {
      const response = await api.get(`/payment/status/${user.id}/${id}`);
      if (response.data.success) {
        console.log("payment status from api",response.data.status)
        setPaymentStatus(response.data.status);
      } else {
        setPaymentStatus(null);
      }
    } catch (error) {
      console.error("Error retrieving payment status:", error);
      setPaymentStatus(null); 
    }
  };

  const fetchPujaDetails = async () => {
    try {
      const response = await api.get(`/user/onlinePuja/get/${id}`);
      if (response.data.success) {
        const puja = response.data.data[0];
        setPujaDetails(puja);

        // Date comparison logic
        const currentDate = new Date();
        const pujaDate = new Date(puja.date);
        setPujaTime(puja.time);
        setIsPastDate(pujaDate < currentDate);
      } else {
        setError("Failed to fetch details");
      }
    } catch (error) {
      setError("An error occurred while fetching puja details");
      console.error("Error fetching puja details:", error);
    }
  };

  // const handlePayment = async (price, name) => {
  //   try {
  //     const order = await api.post("/payment/create-payment", {
  //       amount: price,
  //       currency: "INR",
  //       user_id: user.id,
  //       puja: name,
  //       puja_id: id,
  //     });

  //     const options = {
  //       description: name,
  //       image: "../../assets/sidhi-vinayak-image.png",
  //       currency: "INR",
  //       key: "rzp_test_8aBANy228Ye4l3",
  //       amount: order.data.data.amount,
  //       order_id: order.data.data.id,
  //       prefill: {
  //         email: user.email,
  //         contact: user.mobile,
  //         name: user.name,
  //       },
  //       theme: { color: "blue" },
  //     };

  //     const razorpay = new window.Razorpay(options);
  //     razorpay.open();
  //     razorpay.on("payment.success", async (response) => {
  //       try {
  //         const verificationResponse = await api.post(
  //           "/payment/verify-payment",
  //           {
  //             razorpay_order_id: response.razorpay_order_id,
  //             razorpay_payment_id: response.razorpay_payment_id,
  //             razorpay_signature: response.razorpay_signature,
  //           }
  //         );
  //         alert(`Payment Status: ${verificationResponse.data.message}`);
  //         if (verificationResponse.data.success) {
  //           setPaymentStatus("success");
  //         } else {
  //           setPaymentStatus(null);
  //         }
  //       } catch (error) {
  //         console.error("Payment verification failed:", error);
  //         alert("Payment verification failed");
  //       }
  //     });
  //     razorpay.on("payment.error", (error) => {
  //       console.error("Payment failed:", error);
  //       alert(`Error: ${error.error.description}`);
  //     });
  //   } catch (error) {
  //     console.error("Payment initiation failed:", error);
  //   }
  // };
  
  const handlePayment = async (price,name) => {
    if (!user) {
      navigate("/login"); // Redirect to login page if user is not logged in
      return;
    }
  
    try {
      const order = await api.post("/payment/create-payment", {
        amount: price,
        currency: "INR",
        user_id: user.id,
        puja: name,
        puja_id : id
      });

      const options = {
        key: "rzp_test_8aBANy228Ye4l3", 
        amount: order.data.data.amount,
        currency: "INR",
        name: user.name,
        description: name,
        order_id: order.data.data.id,
        handler: async function (response) {
          try {
            const verifyResponse = await api.post("/payment/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            alert(`Payment Status: ${verifyResponse.data.message}`);
            if (verifyResponse.data.success) {
                        setPaymentStatus("success");
                      } else {
                        setPaymentStatus(null);
                      }
          
          } catch (error) {
            console.error("Verification failed:", error);
          }
        },
        prefill: {
          email: user.email,
          contact: user.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          upi: true,
          qr: true,
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        alert(`Error: ${response.error.code} | ${response.error.description}`);
      });
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  const calculateTimeLeft = (pujaDate, pujaTime) => {
    const currentDate = new Date();
    const [hours, minutes] = pujaTime.split(":");
    const pujaDateTime = new Date(pujaDate);
    pujaDateTime.setHours(hours, minutes);
  
    const timeDifference = pujaDateTime - currentDate;
  
    if (timeDifference > 0) {
      const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesLeft = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
  
      return `${daysLeft} days, ${hoursLeft} hours, and ${minutesLeft} minutes left for pooja`;
    } else {
      return "Pooja has already started";
    }
  };

  const formatDateTime = (dateString, timeString) => {
    const date = new Date(dateString);
    const [hours, minutes] = timeString.split(":"); 
    date.setHours(hours, minutes);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  console.log("here is payment status",paymentStatus)

  const handleLivePooja = async () => {
    try {
      const roomName = pujaDetails.name;
      const response = await api.post('/live_stream/token', {
        user_id: user.id,
        room: roomName
      });
      if (response.data.success) {
        navigate(`/videoCall/${roomName}/${response.data.token}`);
      } else {
        console.error('Failed to fetch token:', response.data.message);
      }
    } catch (error) {
      console.error('Error initiating live pooja:', error);
    }
  };

  return (
    <div className="category_heading">
      <div className="container">
        {pujaDetails ? (
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={pujaDetails.image} alt={pujaDetails.name} />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">{pujaDetails.name}</h1>
              <div className="product_name">
                <p>Removes Negativity & Solves Problems</p>
              </div>
              <div className="timeshedule_section">
                {isPastDate ? (
                  <span>
                    Pooja has already started on Date:{" "}
                    {formatDateTime(pujaDetails.date, pujaTime)}
                  </span>
                ) : paymentStatus === "success" ? (
                  <span>{calculateTimeLeft(pujaDetails.date, pujaTime)}</span>
                ) : (
                  <span>
                    Pooja will start on Date: {formatDateTime(pujaDetails.date, pujaTime)}
                  </span>
                )}
              </div>
              {!isPastDate && (
                <div className="booknow-btn">
                  {paymentStatus === "success" ? (
                    <button className="booknoww" onClick={handleLivePooja}>
                      Join Now
                    </button>
                  ) : (
                    <button
                      className="booknoww"
                      onClick={() =>
                        handlePayment(pujaDetails.price, pujaDetails.name)
                      }
                    >
                      Book Now
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Poojapage;
