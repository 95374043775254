import React from "react";
import { Link } from "react-router-dom";
import "../../styles/muhurat.css";

import vivahmuhuratimg from "../Assets/muhurat8.jpg";
import subhmuhuratimg from "../Assets/muhurat2.jpg";
import propertypurchaseimg from "../Assets/muhurat3.jpg";
import vehicalpurchaseimg from "../Assets/muhurat4.webp";
import grihapraveshimg from "../Assets/muhurat7.jpg";

const Muhurat = () => {
  return (
    <>
      <div className="sub_header_muhurat">
        <div className="container">
          <div className="subheader_inner_muhurat">
            <div className="subheader_text_muhurat">
              <h1>Muhurat</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Muhurat</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="muhurat-list">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="muhurat-img-box">
                <Link to="/panditmuhuratprofile">
                  <img src={vivahmuhuratimg} alt="Vivah Muhurat" />
                  <p>Vivah Muhurat</p>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="muhurat-img-box">
                <Link to="/panditmuhuratprofile">
                  <img src={subhmuhuratimg} alt="Subh Muhurat" />
                  <p>Pujan Muhurat</p>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="muhurat-img-box">
                <Link to="/panditmuhuratprofile">
                  <img src={propertypurchaseimg} alt="Vehicle Purchase" />
                  <p>Vehicle Purchase</p>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="muhurat-img-box">
                <Link to="/panditmuhuratprofile">
                  <img src={vehicalpurchaseimg} alt="Property Purchase" />
                  <p>Property Purchase</p>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="muhurat-img-box">
                <Link to="/panditmuhuratprofile">
                  <img src={grihapraveshimg} alt="Griha Pravesh" />
                  <p>Griha Pravesh</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Muhurat;
