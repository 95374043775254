import React from "react";
import "../../styles/kalsharpdosh.css";

import kalsharpimg from "../Assets/pitra-dosh-pooja.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
 import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Pitradoshpooja = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={kalsharpimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Pitra Dosh Pooja</h1>
              <div className="product_name">
                <p>
                  Pitra Dosh Shanti Pooja Karein Aur Purwajon Ka Ashirvad
                  Paayein
                </p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME”, an Indian Vedic Astrologer, offers expert guidance
                  aimed at fostering stability and clarity in life. Through
                  straightforward remedies and personalized attention, he
                  empowers clients, ensuring their satisfaction and trust in his
                  solutions.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Seek blessings from ancestors to receive their guidance
                    and support.</li>
                    <li>Remove all obstacles that may hinder progress in life.</li>
                    <li>Alleviate financial troubles and foster stability in
                    monetary matters.</li>
                    <li>Enhance the likelihood of finding a suitable
                    marriage partner.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>Schedule the Pooja. Provide your name and surname/Gotra
                    for the Sankalp, which the Pandit will take.</li>
                    <li>Have a Dupatta, Handkerchief, or Towel ready to cover your
                    head at the beginning of the Pooja.</li>
                    <li>Listen attentively to the Pooja and seek blessings with
                    devotion.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Pitra Dosh</h2>
                <div className="next_details">
                  <ul>
                    <li>Pitra Dosh signifies the negative karma accumulated by
                    ancestors due to their past wrongdoings.</li>
                    <li>If ancestors have committed crimes, mistakes, or sins,
                    their descendants may inherit Pitra Dosh in their horoscope.</li>
                    <li>Pitra Dosh can also arise when family members
                    neglect annual rituals for their ancestors or fail to
                    provide proper care for elderly relatives, leaving them to
                    fend for themselves.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Offer food to Brahmins on every Amavasya (new moon day).</li>
                    <li>Perform the Pooja regularly each month to ensure
                    continued benefits and blessings.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>Prabhu Pooja is India's largest Devotion tech platform,
                    offering access to top astrologers and Pandits.</li>
                    <li>Our Pooja services feature the best Pandits on the
                    platform to ensure you receive the maximum benefits.</li>
                    <li>This Pooja includes family participation and covers
                    Dakshina as well.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pitradoshpooja;
