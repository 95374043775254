import React, { useEffect, useState } from "react";
import "../../styles/pandit.css";
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../Axios/api";
import loader from "../Assets/loader.svg";

import panditprofileimg from "../Assets/muhuratpanditimg1.jpg";

const Panditmuhuratprofile = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get(
          "/pandit/pandit"
        );

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  const createRequest = async (
    astrologerId,
    type,
    price,
    astrologerPhoneNumber
  ) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    try {
      setModalVisible(true);
      const response = await api.post("/request", {
        user_id: user.id,
        pandit_astrologer_id: astrologerId,
        request_type: type,
        status: "pending",
      });

      const requestId = response.data.requestId;
      pollRequestStatus(
        requestId,
        type,
        astrologerId,
        price,
        astrologerPhoneNumber
      );
    } catch (error) {
      console.error("Failed to create request:", error);
      setError("Failed to create request.");
      setModalVisible(false);
    }
  };

  const pollRequestStatus = (
    requestId,
    type,
    astrologerId,
    price,
    astrologerPhoneNumber
  ) => {
    console.log("in polrequest function", requestId);
    const intervalId = setInterval(async () => {
      try {
        const response = await api.get(`/request/status/${requestId}`);
        console.log("here is the status", response.data.status);
        setRequestStatus(response.data.status);

        if (response.data.status !== "pending") {
          clearInterval(intervalId);
          setModalVisible(false);
          console.log(response.data.status);
          if (response.data.status === "accepted") {
            if (type === "chat") {
              navigate(`/chat/${astrologerId}/${price}/${requestId}`);
            } else if (type === "voice" || type === "video") {
              initiateCall(
                astrologerId,
                type,
                astrologerPhoneNumber,
                requestId
              );
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch request status:", error);
        clearInterval(intervalId);
        setModalVisible(false);
      }
    }, 5000); // Poll every 5 seconds
  };

  const initiateCall = async (
    astrologerId,
    type,
    astrologerPhoneNumber,
    requestId
  ) => {
    try {
      const response = await api.post("/call/initiate", {
        callerId: user.id,
        receiverId: astrologerId,
        type,
        callerPhoneNumber: user.mobile,
        receiverPhoneNumber: astrologerPhoneNumber,
        request_id: requestId,
      });

      if (response.data.success) {
        alert("Call initiated successfully");
        if (type === "voice") {
          navigate(`/voicecall/${response.data.call.twilioCallSid}`);
        } else if (type === "video") {
          navigate(
            `/videocall/${response.data.call.roomName}/${response.data.call.callerToken}`
          );
        }
      } else {
        alert("Error", response.data.message);
      }
    } catch (error) {
      console.error("Failed to initiate call:", error);
      alert("Failed to initiate call");
    }
  };

  const handleChat = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }

    createRequest(astrologerId, "chat", price);
  };

  const handleVoiceCall = (astrologerId, mobile, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }

    createRequest(astrologerId, "voice", price, mobile);
  };

  const handleVideoCall = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }

    createRequest(astrologerId, "video", price);
  };
  const handlePayment = async (price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    try {
      const order = await api.post("/payment/create-payment", {
        amount: price,
        currency: "INR",
        user_id: user.id,
        puja: "Astrology",
      });

      const options = {
        key: "rzp_test_8aBANy228Ye4l3", // Replace with your Razorpay key
        amount: order.data.data.amount,
        currency: "INR",
        name: user.name,
        description: "Astrology",
        order_id: order.data.data.id,
        handler: async function (response) {
          try {
            const verifyResponse = await api.post("/payment/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            alert(`Payment Status: ${verifyResponse.data.message}`);
            
          } catch (error) {
            console.error("Verification failed:", error);
          }
        },
        prefill: {
          email: user.email,
          contact: user.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          upi: true,
          qr: true,
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        alert(`Error: ${response.error.code} | ${response.error.description}`);
      });
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  return (
    <>
      <section className="pandit_section muhurat_section">
        <div className="container">
          <form action="">
            <div className="pandit_baar">
              <div className="panditbaar">
                <h1 className="heading_chat_with_pandit">Talk to Pandit</h1>
              </div>

              {/* <div className="panditbaar">
                <div className="recharge_btn">
                  <Link className="medium-screen">Recharge</Link>
                  <button className="filter">
                    <i class="fa-solid fa-filter"></i> Filter
                  </button>
                  <div className="form-search search-mobile">
                    <input
                      type="search"
                      placeholder="Search name...."
                      className="form-control search-input"
                    />
                    <i class="fa-solid fa-magnifying-glass search_icon"></i>
                  </div>
                </div>
              </div> */}
            </div>
          </form>

          <div className="best-pandit">
            <div className="row">
              {/* <div className="col-sm-4 pandit-section-start">
                <div className="pandit-box pandit_boxes">
                  <div className="box-upper-details">
                    <div className="pandit-details">
                      <div className="pandit-img">
                        <div className="pandit_image normal-border">
                          <Link to="/panditaboutprofile">
                            <img src={panditprofileimg} alt="" />
                          </Link>
                        </div>
                        <div className="star-rate">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>

                      <div className="pandit-bio">
                        <div className="pandit-name">Dayanand</div>
                        <div className="pandit-designation light-clr">
                          <span>Vedic</span>
                        </div>
                        <div className="pandit-language light-clr">
                          <span>English,</span>
                          <span>Hindi</span>
                        </div>
                        <div className="pandit-experience light-clr">
                          Exp: 10 Years
                        </div>
                        <div className="pandit-price-chat">
                          <span className="redBold">500 ₹ / 30 min</span>
                        </div>
                      </div>
                    </div>

                    <div className="chat-btn-pandit muhurat_btn">
                      <div className="chat-button">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Call
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="chat-btn-pandit">
                      <div className="chat-button">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Chat
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="chat-btn-pandit">
                      <div className="chat-button pandit_btn">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Book Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 pandit-section-start">
                <div className="pandit-box pandit_boxes1">
                  <div className="box-upper-details">
                    <div className="pandit-details">
                      <div className="pandit-img">
                        <div className="pandit_image normal-border">
                          <Link to="/panditaboutprofile">
                            <img src={panditprofileimg} alt="" />
                          </Link>
                        </div>
                        <div className="star-rate">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>

                      <div className="pandit-bio">
                        <div className="pandit-name">Dayanand</div>
                        <div className="pandit-designation light-clr">
                          <span>Vedic</span>
                        </div>
                        <div className="pandit-language light-clr">
                          <span>English,</span>
                          <span>Hindi</span>
                        </div>
                        <div className="pandit-experience light-clr">
                          Exp: 10 Years
                        </div>
                        <div className="pandit-price-chat">
                          <span className="redBold">500 ₹ / 30 min</span>
                        </div>
                      </div>
                    </div>

                    <div className="chat-btn-pandit muhurat_btn">
                      <div className="chat-button">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Call
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="chat-btn-pandit">
                      <div className="chat-button">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Chat
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="chat-btn-pandit">
                      <div className="chat-button pandit_btn">
                        <Link>
                          <button className="btn_chat btn_chat_online">
                            Book Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
{services.map((service, index) => (
              <div className="col-sm-4 pandit-section-start" key={index}>
                <div className="pandit-box pandit_boxes2">
                  <div className="box-upper-details1">
                    <div className="pandit-details">
                      <div className="pandit-img">
                        <div className="pandit_image normal-border">
                          <Link to={`/panditaboutprofile/${slugify(service.id)}`}>
                            <img src={`${service.profileImage}`} alt="" />
                          </Link>
                        </div>
                        <div className="star-rate">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>

                      <div className="pandit-bio">
                        <div className="pandit-name">{service.name}</div>
                        <div className="pandit-designation light-clr">
                          <span>{service.skills}</span>
                        </div>
                        <div className="pandit-language light-clr">
                          <span>{service.language}</span>
                          
                        </div>
                        <div className="pandit-experience light-clr">
                          Exp: {service.experience}
                        </div>
                        <div className="pandit-price-chat">
                          <span className="redBold">{service.price} ₹ / 30 min</span>
                        </div>
                      </div>
                    </div>

                    <div className="pandit_all_btn">
                      <div className="chat-btn-pandit">
                        <div className="chat-button">
                          <Link>
                            <button className="btn_chat btn_chat_online" onClick={() =>
                        handleVoiceCall(
                          service.id,
                          service.mobile,
                          service.price
                        )
                      }>
                        
                              Call
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="chat-btn-pandit">
                        <div className="chat-button">
                          <Link>
                            <button className="btn_chat btn_chat_online" onClick={() =>
                        handleChat(service.id, service.price)
                      }>
                              Chat
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="chat-btn-pandit">
                        <div className="chat-button pandit_btn">
                          <Link>
                            <button className="btn_chat btn_chat_online" onClick={() => handlePayment(service.price)}>
                              Book Now
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  ))}
            </div>
          </div>
        </div>
      </section>
      {modalVisible && (
        <div className="loader">
          <div className="spinner">
            <img src={loader} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default Panditmuhuratprofile;
