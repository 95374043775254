
import React, { useEffect, useState } from "react";
// import womenimg from "../Assets/astrologer-img.jpg";
import "../../styles/astrologyprofile.css";
import api from "../Axios/api";
import { useParams } from "react-router-dom";

const AstrologyProfile = () => {
  const [panditData, setPanditData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    const fetchPanditData = async () => {
      try {
        const response = await api.get(`/pandit/id/${id}`);
        setPanditData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchPanditData();
  }, [id]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!panditData) return <div className="no-data">No Pandit Data</div>;

  return (
    <section className="astrology-profile">
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <div className="profile-picture">
              <img
                src={panditData.profileImage}
                alt={panditData.name || "Astrologer"}
                className="profile-img"
              />
            </div>
            <div className="profile-details">
              <h1 className="profile-name">{panditData.name}</h1>
              <div className="profile-profession">{panditData.skills}</div>
              <div className="profile-language">{panditData.language}</div>
              <div className="profile-experience">Exp: {panditData.experience} Years</div>
              <div className="profile-price">
                <span className="price-amount">₹ {panditData.price}<span>/min</span></span>
              </div>

              <div className="profile-actions">
                <button className="action-btn chat-btn">Chat</button>
                <button className="action-btn call-btn">Call</button>
                <button className="action-btn video-call-btn">Video Call</button>
              </div>
            </div>
          </div>

          <div className="profile-about">
            <h2 className="about-heading">About Me</h2>
            <p className="about-text">
              {panditData.name} is a {panditData.skills} Astrologer in India. He will consult you
              regarding Marriage Consultation, Career and Business, Love
              and Relationship, Wealth and Property, Career issues, and
              much more. The remedies he provides are very easy and
              effective and are proven to be accurate most of the time.
              Moreover, his customers are always satisfied with his
              solutions and remedies. He treats all his customers on a
              personal level and tries to build a relationship with them.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AstrologyProfile;



