import React from "react";
import "../../styles/kalsharpdosh.css";

import rahuketuimg from "../Assets/rudraabhishek-img.jpeg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
// import Poojapage from "../poojapage/poojapage";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Rudraabhishek = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={rahuketuimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Rudraabhishek</h1>
              <div className="product_name">
                <p>Wipes Out All Sins & Purifies The Atmosphere</p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME” is a well-known astrologer with over 15 years of
                  experience, specializing in Vedic and Prashana astrology, as
                  well as face reading. He provides practical solutions for life
                  challenges including business, marriage, and health issues.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Promotes personal and professional growth.</li>
                    <li>Removes negativity from life.</li>
                    <li>Corrects doshas (negative influences).</li>
                    <li>Enhances harmony in relationships.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>Input your Name and Surname/Gotra for Sankalp.</li>
                    <li>You can attend Pooja live on video call.</li>
                    <li>Take the blessings.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Rudraabhishek pooja</h2>
                <div className="next_details">
                  <ul>
                    <li>Shiva is known as the god of destruction.</li>
                    <li>
                      Married to Goddess Parvati, symbolizing divine feminine
                      energy.
                    </li>
                    <li>
                      Shiva is revered as a yogi and remover of ignorance,
                      guiding towards spiritual awakening and liberation
                      (moksha).
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Practice gratitude for blessings received.</li>
                    <li>Perform the Pooja monthly for continuous benefits.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>
                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rudraabhishek;
