import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/header";
import Home from "./Components/home";
import Signup from "./Components/signup/Signup";
import Login from "./Components/login/Login";
import Otp from "./Components/otp/Otp";
import Footer from "./Components/footer";
import Onlinepuja from "./Components/onlinepuja/onlinepuja";
import About from "./Components/about/about";
import Pandit_form from "./Components/form/pandit_form";
import Kalsharpdosh from "./Components/kalsharpdosh/kalsharpdosh";
import Rahuketupooja from "./Components/rahuketupooja/rahuketupooja";
import Pitradoshpooja from "./Components/pitradoshpooja/pitradoshpooja";
import Shrimahamritunejayjaap from "./Components/shrimahamritunejayjaap/shrimahamritunejayjaap";
import Temple from "./Components/temple/temple";
import Navgrah from "./Components/navgrah/navgrah";
import Mangaldosh from "./Components/mangaldosh/mangaldosh";
import Satyanarayan from "./Components/satyanarayan/satyanarayan";
import Khajranatemple from "./Components/khajranatemple/khajranatemple";
import Sundarkand from "./Components/sundarkand/sundarkand";
import Rudraabhishek from "./Components/rudraabhishek/rudraabhishek";
import Siddhivinayak from "./Components/siddhivinayak/siddhivinayak";
import Rinmukti from "./Components/rinmukti/rinmukti";
import Vastushanti from "./Components/vastudosh/vastudosh";
import Ujjaintemple from "./Components/ujjaintemple/ujjaintemple";
import Prasaddelivery from "./Components/prasaddelivery/prasaddelivery";
import Khajranaprasad from "./Components/khajranaprasadonline/khajranaprasad";
import Ujjainprasad from "./Components/ujjainprasadonline/ujjainprasad";
import Astrology from "./Components/astrology/astrology";
import Astrologyprofile from "./Components/astrology/astrologyprofile";
import Checkout from "./Components/checkout/checkout";
import Ecomerce from "./Components/ecomerce/ecomerce";
import Pandit from "./Components/pandit/pandit";
import Yoga from "./Components/yoga/yoga";
import Astrologyprofileman from "./Components/astrology/astrologyprofileman";
import Membership from "./Components/membership/membership";
import Enquiryform from "./Components/pandit/enquiryform";
import Panditprofile from "./Components/pandit/panditprofile";
import Booknowform from "./Components/temple/booknowform";
import Muhurat from "./Components/muhurat/muhurat";
import Panditprofilemuhurat from "./Components/muhurat/panditprofilemuhurat";
import Panditaboutprofile from "./Components/muhurat/panditaboutprofile";
import Productdetails from "./Components/ecomerce/productdetails";
import Buymembership from "./Components/membership/buymembership";
// import callComponent from "./Components/calling/callComponenst"
import Astrologyform from "./Components/astrology/astrologyform";
import Recharge from "./Components/astrology/recharge";
import Loginprofile from "./Components/login/loginprofile";
import Chat from "./Components/astrology/chat"
import VideoCall from "./Components/astrology/videoCall"
import Privacypolicy from "./Components/privacypolicy";
import UpdateDetailsUser from "./Components/membership/updatedetailsuser";
import Termsandcondition from "./Components/termsandcondition";
import BuyNowForm from "./Components/ecomerce/buynowform";
// import Poojapanditprofile from "./Components/onlinepuja/poojapanditprofile";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/calling" element={<callComponent/>} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/loginprofile" element={<Loginprofile />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/onlinepooja" element={<Onlinepuja />} />

          <Route path="/kaalsarpdoshnivaranpooja/:id" element={<Kalsharpdosh />} />
          <Route path="/rahuketupooja/:id" element={<Rahuketupooja />} />
          <Route path="/pitradoshpooja/:id" element={<Pitradoshpooja />} />
          <Route path="/navgrahpooja/:id" element={<Navgrah />} />
          <Route path="/mangaldoshnivaranpooja/:id" element={<Mangaldosh />} />
          <Route path="/satyanarayanpooja/:id" element={<Satyanarayan />} />
          <Route path="/sundarkandpathpooja/:id" element={<Sundarkand />} />
          <Route path="/rudraabhishek/:id" element={<Rudraabhishek />} />
          <Route path="/vastushantipooja/:id" element={<Vastushanti />} />
          <Route path="/rinmuktipooja/:id" element={<Rinmukti />} />
          <Route path="/siddhivinayakpooja/:id" element={<Siddhivinayak />} />
          <Route
            path="/shrimahamritunjayjaap/:id"
            element={<Shrimahamritunejayjaap />}
          />

          <Route path="/temple" element={<Temple />} />
          <Route path="/booknowform" element={<Booknowform />} />
          <Route path="/khajranatemple" element={<Khajranatemple />} />
          <Route path="/ujjaintemple" element={<Ujjaintemple />} />
          
          <Route path="/prasaddelivery" element={<Prasaddelivery />} />
          <Route path="/khajranamandir" element={<Khajranaprasad />} />
          <Route path="/mahakalmandirujjain" element={<Ujjainprasad />} />
          <Route path="/astrology" element={<Astrology />} />
          <Route path="/recharge" element={<Recharge />} />
          <Route path="/astrologyform" element={<Astrologyform />} />
          <Route path="/astrologyprofile" element={<Astrologyprofile />} />
          <Route
            path="/astrologyprofileman"
            element={<Astrologyprofileman />}
          />
          <Route path="/astrologyprofile/:id" element={<Astrologyprofile />} />
          {/* <Route path="/astrologyprofileman" element={<Astrologyprofileman />} /> */}
          <Route path="/panditform" element={<Pandit_form />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/ecommerce" element={<Ecomerce />} />
          <Route path="/productdetails" element={<Productdetails />} />
          <Route path="/buynowform" element={<BuyNowForm />} />
          <Route path="/pandit" element={<Pandit />} />
          <Route path="/panditprofile/:id" element={<Panditprofile />} />
          <Route path="/enquiryform" element={<Enquiryform />} />
          <Route path="/yoga" element={<Yoga />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/buymembership" element={<Buymembership />} />
          <Route path="/updatedetailsuser" element={<UpdateDetailsUser />} />
          <Route path="/muhurat" element={<Muhurat />} />
          <Route
            path="/panditmuhuratprofile"
            element={<Panditprofilemuhurat />}
          />
          <Route
            path="/privacypolicy"
            element={<Privacypolicy />}
          />
          <Route
            path="/termsandcondition"
            element={<Termsandcondition />}
          />
          <Route path="/panditaboutprofile/:id" element={<Panditaboutprofile />} />
          <Route path="/muhurat" element={<Muhurat />} /> 
          <Route path="/panditmuhuratprofile" element={<Panditprofilemuhurat />} /> 
          <Route path="/panditaboutprofile" element={<Panditaboutprofile />} /> 
          <Route path="/chat/:astrologerId/:price/:requestId" element={<Chat />} /> 
          <Route path="/videoCall/:roomName/:token" element={<VideoCall />} /> 
          <Route path="/videoCall" element={<VideoCall />} /> 
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
