import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Components/Assets/logo-image-prabhupooja2.png";
import "../styles/header.css";
import "../styles/button.css";
import { navItems, serviceDropdown } from "./Navitems";
import Dropdown from "./Dropdown";
import { FaBars, FaTimes } from "react-icons/fa";
import userprofileimg from "../Components/Assets/userprofile3.png";
import api from "./Axios/api";

const Header = () => {
  const [dropdown, setDropdown] = useState(false); // For Desktop Dropdown
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // For Mobile Menu
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const profileMenuRef = useRef(null);
  const menuRef = useRef(null); // Ref for detecting clicks outside of the mobile menu

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem("token");
      const isVerified = localStorage.getItem("isVerified");
      const user = JSON.parse(localStorage.getItem("users"));

      if (token && isVerified === "true") {
        setIsLoggedIn(true);
        if (user) {
          setUserName(user.name);
          setUserMobile(user.mobile);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();

    const handleLoginStatusChange = () => {
      checkAuthStatus();
    };

    window.addEventListener("loginStatusChanged", handleLoginStatusChange);

    return () => {
      window.removeEventListener("loginStatusChanged", handleLoginStatusChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("users");
    setIsLoggedIn(false);
    setUserName("");
    setUserMobile("");
    setProfileMenuOpen(false);
    navigate("/");
    window.dispatchEvent(new Event("loginStatusChanged"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
    }
  }, [isLoggedIn]);

  const fetchBalance = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("users"));
      const response = await api.get(`/users/balance/${user.id}`);
      setBalance(response.data.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  // Close profile menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuOpen &&
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }
      // Close mobile menu when clicking outside
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuOpen, menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setDropdown(false); // Close dropdown when toggling menu
  };

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const closeMobileMenu = () => {
    setMenuOpen(false);
    setDropdown(false); // Close dropdown as well
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="Prabhu Pooja Logo" height={120} width={160} />
      </Link>

      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </div>

      <ul
        className={menuOpen ? "nav-items active" : "nav-items"}
        ref={menuRef} // Add ref to the mobile menu container
      >
        {navItems.map((item) =>
          item.title === "Services" ? (
            window.innerWidth > 768 ? ( // Show dropdown in desktop view
              <li
                key={item.id}
                className={item.cName}
                onClick={toggleDropdown}
              >
                <Link to={item.path}>{item.title}</Link>
                {dropdown && <Dropdown toggleMenu={closeMobileMenu} />}
              </li>
            ) : ( // Show services directly in mobile view
              serviceDropdown.map((service) => (
                <li key={service.id} className="nav-item">
                  <Link
                    to={service.path}
                    className="submenu-item"
                    onClick={closeMobileMenu} // Close menu on link click
                  >
                    {service.title}
                  </Link>
                </li>
              ))
            )
          ) : (
            <li key={item.id} className={item.cName} onClick={closeMobileMenu}>
              <Link to={item.path}>{item.title}</Link>
            </li>
          )
        )}
        {isLoggedIn ? (
          <li
            className="profile-menu"
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
            ref={profileMenuRef}
          >
            <img src={userprofileimg} alt="" className="userimg" />
            {profileMenuOpen && (
              <div className="profile-dropdown">
                <img src={userprofileimg} alt="" className="userimg1" />
                <p>{userName}</p>
                <p>{userMobile}</p>
                <div className="separator"></div>
                <div className="wallet">
                  <p>Wallet balance: ₹ {balance}</p>
                  <button onClick={handleLogout} className="logout-button">
                    Logout
                  </button>
                </div>
              </div>
            )}
          </li>
        ) : (
          <li className="login-button">
            <Link to="/login" className="btn" onClick={closeMobileMenu}>
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Header;
